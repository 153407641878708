<template>
    <div class="wrapper stockTable">
        <!-- 表头 -->
        <div class="t-fixed-header">
            <table class="custom-table" style="width:calc(100% - 10px)">
                <tbody>
                    <tr>
                        <td class="custom-th custom-th-bg" rowspan="2" style="width:6%;">
                            分类
                        </td>
                        <td class="custom-th custom-th-bg" rowspan="2" style="width: 14%;">
                            子类
                        </td>
                        <td class="custom-th custom-th-bg" colspan="4">
                            库存(吨)
                        </td>
                        <td class="custom-th custom-th-bg" colspan="4">
                            占压资金（元）
                        </td>
                    </tr>
                    <tr>
                        <td class="custom-th custom-th-bg" width="10%">
                            库存减少
                        </td>
                        <td class="custom-th custom-th-bg" width="10%">
                            期初库存
                        </td>
                        <td class="custom-th custom-th-bg" width="10%">
                            期末库存
                        </td>
                        <td class="custom-th custom-th-bg" width="10%">
                            核定库存
                        </td>
                        <td class="custom-th custom-th-bg" width="10%">
                            占压资金减少
                        </td>
                        <td class="custom-th custom-th-bg" width="10%">
                            期初占压资金
                        </td>
                        <td class="custom-th custom-th-bg" width="10%">
                            期末占压资金
                        </td>
                        <td class="custom-th custom-th-bg">
                            核定占压资金
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="t-fixed-body">
            <table class="custom-table">
                <tbody>
                    <template v-for="(item, index) in data">
                        <tr :key="index" v-if="item.detail.length>0">
                            <td colspan="2" class="custom-th" width="20%">
                                {{ item.stockType.type_name }}
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.stock_reduce)<0}" width="10%">
                                {{ item.stockType.stock_reduce }}
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.stock_begin)<0}" width="10%">
                                {{ item.stockType.stock_begin }}
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.stock_end)<0}" width="10%">
                                {{ item.stockType.stock_end }}
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.stock_auth)<0}" width="10%">
                                {{ item.stockType.stock_auth }}
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.fund_reduce)<0}" width="10%">
                                {{ item.stockType.fund_reduce }}
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.fund_begin)<0}" width="10%">
                                {{ item.stockType.fund_begin }}
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.fund_end)<0}" width="10%">
                                {{ item.stockType.fund_end }}
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.fund_auth)<0}">
                                {{ item.stockType.fund_auth }}
                            </td>
                        </tr>
                        <tr :key="index" v-if="item.detail.length<=0" :class="{'border6': index < data.length-1}">
                            <td colspan="2" class="custom-th">
                                {{ item.stockType.type_name }}
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.stock_reduce)<0}">
                                {{ item.stockType.stock_reduce }}
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="item.stockType.stock_begin"
                                    @blur="computStock(item.stockType.stock_begin, 'stockType',null,item.stockType,index)"
                                    :class="{'red': parseFloat(item.stockType.stock_begin)<0}"
                                ></el-input>
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="item.stockType.stock_end"
                                    @blur="computStock(item.stockType.stock_end, 'stockType',null,item.stockType,index)"
                                    :class="{'red': parseFloat(item.stockType.stock_end)<0}"
                                ></el-input>
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="item.stockType.stock_auth"
                                    :class="{'red': parseFloat(item.stockType.stock_auth)<0}"
                                    @blur="computStock(item.stockType.stock_auth, 'stockType_fund_auth',null,item.stockType,index)"
                                ></el-input>
                            </td>
                            <td :class="{'red': parseFloat(item.stockType.fund_reduce)<0}">
                                {{ item.stockType.fund_reduce }}
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="item.stockType.fund_begin"
                                    @blur="computStock(item.stockType.fund_begin, 'stockType',null,item.stockType,index)"
                                    :class="{'red': parseFloat(item.stockType.fund_begin)<0}"
                                ></el-input>
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="item.stockType.fund_end"
                                    @blur="computStock(item.stockType.fund_end, 'stockType',null,item.stockType,index)"
                                    :class="{'red': parseFloat(item.stockType.fund_end)<0}"
                                ></el-input>
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="item.stockType.fund_auth"
                                    :class="{'red': parseFloat(item.stockType.fund_auth)<0}"
                                ></el-input>
                            </td>
                        </tr>
                        <template>
                            <tr v-for="(v, vIndex) in item.detail" :key="v.rstd_id" :class="{'border6': vIndex===item.detail.length-1}">
                                <td width="6%"></td>
                                <td width="14%">
                                    {{ v.type_name }}
                                </td>
                                <td :class="{'red': parseFloat(v.stock_reduce)<0}">
                                    {{ v.stock_reduce }}
                                </td>
                                <td>
                                    <el-input
                                        oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                        :disabled="type=='approval'"
                                        v-model="v.stock_begin"
                                        @blur="computStock(v.stock_begin, 'stock',v,item.stockType,index)"
                                        :class="{'red': parseFloat(v.stock_begin)<0}"
                                    ></el-input>
                                </td>
                                <td>
                                    <el-input
                                        oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                        :disabled="type=='approval'"
                                        v-model="v.stock_end"
                                        @blur="computStock(v.stock_end, 'stock',v,item.stockType,index)"
                                        :class="{'red': parseFloat(v.stock_end)<0}"
                                    ></el-input>
                                </td>
                                <td>
                                    <el-input
                                        oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                        :disabled="type=='approval'"
                                        v-model="v.stock_auth"
                                        @blur="computStock(v.stock_auth, 'stock',v,item.stockType,index,vIndex)"
                                        :class="{'red': parseFloat(v.stock_auth)<0}"
                                    ></el-input>
                                </td>
                                <td :class="{'red': parseFloat(v.fund_reduce)<0}">
                                    {{ v.fund_reduce }}
                                </td>
                                <td>
                                    <el-input
                                        oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                        :disabled="type=='approval'"
                                        v-model="v.fund_begin"
                                        @blur="computStock(v.fund_begin, 'fund',v,item.stockType,index)"
                                        :class="{'red': parseFloat(v.fund_begin)<0}"
                                    ></el-input>
                                </td>
                                <td>
                                    <el-input
                                        oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                        :disabled="type=='approval'"
                                        v-model="v.fund_end"
                                        @blur="computStock(v.fund_end, 'fund',v,item.stockType,index)"
                                        :class="{'red': parseFloat(v.fund_end)<0}"
                                    ></el-input>
                                </td>
                                <td>
                                    <el-input
                                        oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                        :disabled="type=='approval'"
                                        v-model="v.fund_auth"
                                        @blur="computStock(v.fund_auth, 'fund',v,item.stockType,index)"
                                        :class="{'red': parseFloat(v.fund_auth)<0}"
                                    ></el-input>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import util from '@/utils/util';
export default {
    components: {},
    props: {
        reportMonth: {
            type: [Object],
        },
        type: {
            type: [String],
        },
        loadObj: {
            type: [Object],
        },
        typeList: {
            type: [Array],
        },
    },
    data() {
        return {
            data: [],
            oldData: [],
            procureRes: null, // 物资采购数据集合
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取 上月库存情况
        getPreMonStock() {
            return this.$axios.get('/interfaceApi/report/stock/month/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取 指定年月的报表实例信息
        getInstance() {
            return this.$axios.get('/interfaceApi/report/instance/get/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取 物资采购-核定价格
        getProcure() {
            return this.$axios.get('/interfaceApi/report/procure/' + this.reportMonth.report_id);
        },
        // 获取填报详情
        getFillInfo() {
            return this.$axios.get('/interfaceApi/report/instance/fillinfo/' + this.reportMonth.report_id);
        },
        // 获取 库存情况
        getStock() {
            this.$axios
                .get('/interfaceApi/report/stock/' + this.reportMonth.report_id)
                .then(async res => {
                    if (res) {
                        this.data = res;
                        this.oldData = JSON.parse(JSON.stringify(this.data));
                        if (this.type !== 'approval') {
                            if (this.typeList[5].state === 0) {
                                // 获取上月库存情况
                                if (this.reportMonth.report_month > 1) {
                                    const instanceRes = await this.getInstance();
                                    if (instanceRes) {
                                        // eslint-disable-next-line max-depth
                                        if (instanceRes.report_state === 3) {
                                            const fillInfoRes = await this.getFillInfo();
                                            const preStockRes = await this.getPreMonStock();
                                            // eslint-disable-next-line max-depth
                                            if (fillInfoRes.infos[4].state === 0) {
                                                this.$message.warning('请先填写物资采购，以计算核定占压资金');
                                                // eslint-disable-next-line max-depth
                                                if (preStockRes[0].stockType.rst_id) {
                                                    this.data.map((item, index) => {
                                                        item.stockType.stock_begin = preStockRes[index].stockType.stock_end;
                                                        item.stockType.fund_begin = preStockRes[index].stockType.fund_end;
                                                        item.stockType.stock_auth = preStockRes[index].stockType.stock_auth;
                                                        item.detail.map((v, vIndex) => {
                                                            v.stock_begin = preStockRes[index].detail[vIndex].stock_end;
                                                            v.fund_begin = preStockRes[index].detail[vIndex].fund_end;
                                                            v.stock_auth = preStockRes[index].detail[vIndex].stock_auth;
                                                        });
                                                        return item;
                                                    });
                                                    // this.oldData = JSON.parse(JSON.stringify(this.data));
                                                }
                                            } else {
                                                this.procureRes = await this.getProcure();
                                                // eslint-disable-next-line max-depth
                                                if (this.procureRes) {
                                                    // eslint-disable-next-line max-depth
                                                    if (preStockRes[0].stockType.rst_id) {
                                                        this.data.map((item, index) => {
                                                            item.stockType.stock_begin = preStockRes[index].stockType.stock_end;
                                                            item.stockType.fund_begin = preStockRes[index].stockType.fund_end;
                                                            item.stockType.stock_auth = preStockRes[index].stockType.stock_auth;
                                                            if (parseFloat(this.procureRes[index].procureType.price_auth)
                                                            && parseFloat(item.stockType.stock_auth)) {
                                                                item.stockType.fund_auth = util.toFixed6(parseFloat(
                                                                    this.procureRes[index].procureType.price_auth)
                                                                * parseFloat(item.stockType.stock_auth));
                                                            } else {
                                                                item.stockType.fund_auth = 0;
                                                            }

                                                            item.detail.map((v, vIndex) => {
                                                                v.stock_begin = preStockRes[index].detail[vIndex].stock_end;
                                                                v.fund_begin = preStockRes[index].detail[vIndex].fund_end;
                                                                v.stock_auth = preStockRes[index].detail[vIndex].stock_auth;
                                                                if (parseFloat(this.procureRes[index].detail[vIndex].price_auth)
                                                                && parseFloat(v.stock_auth)) {
                                                                    v.fund_auth = util.toFixed6(parseFloat(
                                                                        this.procureRes[index].detail[vIndex].price_auth) * parseFloat(v.stock_auth));
                                                                } else {
                                                                    v.fund_auth = 0;
                                                                }

                                                            });
                                                            return item;
                                                        });
                                                    }
                                                }
                                            }

                                        }
                                    }

                                }
                            }
                        }
                        
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存-库存情况
        save() {
            const newData = {
                stockType: [],
                detail: [],
            };
            const _this = this;
            this.oldData.map((item, index) => {
                const procureTypeB = util.Compare(item.stockType, _this.data[index].stockType);
                if (!procureTypeB) {
                    newData.stockType.push(_this.data[index].stockType);
                }
                item.detail.map((v, vIndex) => {
                    const detailB = util.Compare(v, _this.data[index].detail[vIndex]);
                    if (!detailB) {
                        newData.detail.push(_this.data[index].detail[vIndex]);
                    }
                });
            });
            if (newData.stockType.length<1&&newData.detail.length<1) {
                this.$message.warning('暂无修改内容！');
                this.$emit('save-end', 'sales', 'validateFailed');
                return;
            }
            let stockTypeFalse = false;
            if (newData.stockType.length>0) {
                newData.stockType = newData.stockType.map(item=>{
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rst_id' || key === 'report_id'|| key === 'type_code'|| key === 'type_name'|| key === 'sort_num') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (key==='stock_begin'||key==='stock_end'||key==='stock_auth'||key==='fund_begin'||key==='fund_end'||key==='fund_auth') {
                                if (item[key] < 0) {
                                    stockTypeFalse = true;
                                }
                            }
                        }
                    }
                    return item;
                });
            }
            let detailFalse = false;
            if (newData.detail.length>0) {
                newData.detail = newData.detail.map(item=>{
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rstd_id' || key === 'report_id'|| key === 'stock_type'|| key === 'type_code'|| key === 'type_name'|| key === 'sort_num') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (key==='stock_begin'||key==='stock_end'||key==='stock_auth'||key==='fund_begin'||key==='fund_end'||key==='fund_auth') {
                                if (item[key] < 0) {
                                    detailFalse = true;
                                }
                            }
                            
                        }
                    }
                    return item;
                });
            }
            let isExistMinus = false;
            if (stockTypeFalse||detailFalse) {
                isExistMinus = true;
            }
            if (!isExistMinus) {
                this.$axios
                .post('/interfaceApi/report/stock/save', newData)
                .then(res => {
                    if (res) {
                        if(res.stockType.length>0){
                            res.stockType.map(item=>{
                                this.data = this.data.map(v=>{
                                    if(item.type_code === v.stockType.type_code){
                                        v.stockType = item;
                                    }
                                    return v;
                                });
                            });
                        }
                        if (res.detail.length>0) {
                            res.detail.map(item=>{
                                this.data = this.data.map(v=>{
                                    v.detail = v.detail.map(detailItem=>{
                                        if(item.stock_type === detailItem.stock_type&&item.sort_num === detailItem.sort_num){
                                            detailItem = item;
                                        }
                                        return detailItem;
                                    });
                                    return v;
                                });
                            });
                        }
                        this.$message.success('保存成功');
                        this.$emit('save-end', 'stock', 'success');
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.$emit('save-end', 'sales', 'error');
                });
            } else {
                this.$message.warning('存在负数，请重新填写！');
                this.$emit('save-end', 'sales', 'validateFailed');
            }
        },
        // 计算
        // eslint-disable-next-line max-params
        // eslint-disable-next-line complexity
        async computStock(fieldItem, type, rowData, stockType, dataIndex, vIndex) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            if (type === 'stock') {
                if (!rowData.stock_begin) {
                    rowData.stock_begin = 0;
                }
                if (!rowData.stock_end) {
                    rowData.stock_end = 0;
                }
                rowData.stock_reduce = util.toFixed6(parseFloat(rowData.stock_begin) - parseFloat(rowData.stock_end));
                stockType.stock_begin = 0;
                stockType.stock_end = 0;
                stockType.stock_auth = 0;
                this.data[dataIndex].detail.map(item => {
                    if (item.stock_begin) {
                        stockType.stock_begin = util.toFixed6(parseFloat(stockType.stock_begin) + parseFloat(item.stock_begin));
                    }
                    if (item.stock_end) {
                        stockType.stock_end = util.toFixed6(parseFloat(stockType.stock_end) + parseFloat(item.stock_end));
                    }
                    if (item.stock_auth) {
                        stockType.stock_auth = util.toFixed6(parseFloat(stockType.stock_auth) + parseFloat(item.stock_auth));
                    }
                });
                stockType.stock_reduce = util.toFixed6(parseFloat(stockType.stock_begin) - parseFloat(stockType.stock_end));

                if (!this.procureRes) {
                    this.procureRes = await this.getProcure();
                }
                if (this.procureRes) {
                    if (parseFloat(this.procureRes[dataIndex].procureType.price_auth)&&parseFloat(stockType.stock_auth)) {
                        stockType.fund_auth = util.toFixed6(parseFloat(this.procureRes[dataIndex].procureType.price_auth)
                    * parseFloat(stockType.stock_auth));
                    } else {
                        stockType.fund_auth = 0;
                    }
                    if (parseFloat(this.procureRes[dataIndex].detail[vIndex].price_auth) && parseFloat(rowData.stock_auth)) {
                        rowData.fund_auth = util.toFixed6(parseFloat(this.procureRes[dataIndex].detail[vIndex].price_auth)
                    * parseFloat(rowData.stock_auth));
                    } else {
                        rowData.fund_auth = 0;
                    }
                } else {
                    this.$message.warning('请先填写物资采购，以计算核定占压资金');
                }
            }
            if (type === 'fund') {
                if (!rowData.fund_begin) {
                    rowData.fund_begin = 0;
                }
                if (!rowData.fund_end) {
                    rowData.fund_end = 0;
                }
                rowData.fund_reduce = util.toFixed6(parseFloat(rowData.fund_begin) - parseFloat(rowData.fund_end));
                stockType.fund_begin = 0;
                stockType.fund_end = 0;
                stockType.fund_auth = 0;
                this.data[dataIndex].detail.map(item => {
                    if (item.fund_begin) {
                        stockType.fund_begin = util.toFixed6(parseFloat(stockType.fund_begin) + parseFloat(item.fund_begin));
                    }
                    if (item.fund_end) {
                        stockType.fund_end = util.toFixed6(parseFloat(stockType.fund_end) + parseFloat(item.fund_end));
                    }
                    if (item.fund_auth) {
                        stockType.fund_auth = util.toFixed6(parseFloat(stockType.fund_auth) + parseFloat(item.fund_auth));
                    }
                });
                stockType.fund_reduce = util.toFixed6(parseFloat(stockType.fund_begin) - parseFloat(stockType.fund_end));
            }
            if (type === 'stockType') {
                if (!stockType.stock_begin) {
                    stockType.stock_begin = 0;
                }
                if (!stockType.stock_end) {
                    stockType.stock_end = 0;
                }
                stockType.stock_reduce = util.toFixed6(parseFloat(stockType.stock_begin) - parseFloat(stockType.stock_end));
                if (!stockType.fund_begin) {
                    stockType.fund_begin = 0;
                }
                if (!stockType.fund_end) {
                    stockType.fund_end = 0;
                }
                stockType.fund_reduce = util.toFixed6(parseFloat(stockType.fund_begin) - parseFloat(stockType.fund_end));
            }
            if (type === 'stockType_fund_auth') {
                if (!this.procureRes) {
                    this.procureRes = await this.getProcure();
                }
                if (this.procureRes) {
                    if (parseFloat(stockType.stock_auth) && parseFloat(this.procureRes[dataIndex].procureType.price_auth)) {
                        stockType.fund_auth = util.toFixed6(parseFloat(stockType.stock_auth)
                    * parseFloat(this.procureRes[dataIndex].procureType.price_auth));
                    } else {
                        stockType.fund_auth = 0;
                    }
                } else {
                    this.$message.warning('请先填写物资采购，以计算核定占压资金');
                }
            }
        },
    },
    created() {
        this.getStock();
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.stockTable
    height 100%!important
    position relative
    .t-fixed-header
        height .79rem
        position absolute
        width 100%
        z-index 1000
    .t-fixed-body
        height calc(100% - .8rem)
        position absolute
        top .79rem
        overflow-y auto
</style>